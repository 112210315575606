<template>
  <div id="app">
    <h1>Endpoint Statistics</h1>
    <EndpointStats endpoint="" />
  </div>
</template>

<script>
import EndpointStats from './components/EndpointStats.vue';

export default {
  name: 'App',
  components: {
    EndpointStats
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
