<template>
  <div>
    <div @click="toggle" style="cursor: pointer;">
      <b>{{ endpoint || 'root' }}</b> {{ ( count && !expanded ) ? `(${count} requests)` : '' }}
    </div>
    <div v-if="expanded">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div>
          <strong>Request Count:</strong> {{ stats.count }}
        </div>
        <div>
          Response Times:
          {{ formatNumber( stats.rt.min ) }} .. {{ formatNumber( stats.rt.max ) }}s ({{ formatNumber( stats.rt.average ) }}s)
        </div>
        <div>
          Upstream Connect Times:
          {{ formatNumber( stats.uct.min ) }} .. {{ formatNumber( stats.uct.max ) }}s ({{ formatNumber( stats.uct.average ) }}s)
        </div>
        <div>
          Upstream Header Times:
          {{ formatNumber( stats.uht.min ) }} .. {{ formatNumber( stats.uht.max ) }}s ({{ formatNumber( stats.uht.average ) }}s)
        </div>
        <div>
          Upstream Request Times:
          {{ formatNumber( stats.urt.min ) }} .. {{ formatNumber( stats.urt.max ) }}s ({{ formatNumber( stats.urt.average ) }}s)
        </div>
        <div>
          <strong>HTTP Status Counts:</strong>
          <ul>
            <li v-for="(count, status) in stats.status_counts" :key="status">
              {{ status }}: {{ count }}
            </li>
          </ul>
        </div>
        <div>
          <strong>Endpoints:</strong>
          <ul>
            <li v-for="(data, child) in stats.endpoints" :key="child">
              <EndpointStats :endpoint="`${endpoint}/${child}`" :count="data.count" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EndpointStats from './EndpointStats.vue';

export default {
  name: 'EndpointStats',
  components: {
    EndpointStats
  },
  props: {
    endpoint: {
      type: String,
      default: ''
    },
    count: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      stats: {},
      loading: false,
      expanded: false
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (this.expanded && !this.stats.count) {
        this.fetchStats();
      }
    },
    async fetchStats() {
      this.loading = true;
      try {
        const response = await axios.get(`/analyzer/time-stats`, {
          params: {
            endpoint_filter: this.endpoint,
            file_pattern: '*access-http.log'
          }
        });
        this.stats = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatNumber(value) {
      return parseFloat(value).toFixed(3);
    },
  }
};
</script>
